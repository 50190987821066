import { css } from "goober";

export const cssContainer = () => css`
  & {
    --list-items-visibile: 3;
    --skeleton-color: var(--shade9);
    display: none;
  }

  /* ~ Left column ~ */
  &.suggestions {
    .suggestions-list {
      background: var(--shade7-50);
      border-right: 1px solid var(--card-inset-border);
    }
  }

  /* ~ Right column ~ */
  .suggestions-right {
    position: relative;
    display: grid;
    padding: var(--sp-6);
  }
  .suggestions-left--header {
    gap: var(--sp-2);
    padding: var(--sp-3) var(--sp-3);
  }
  .suggestions[data-loading="true"] .suggestions-left--header {
    opacity: 0.38;
    pointer-events: none;
  }

  .list-header {
    display: block;
    padding: var(--sp-4) var(--sp-4) 0;
    color: var(--shade3);
  }
  .suggestions-lists {
    display: grid;
    grid-auto-rows: max-content;
    gap: var(--sp-2);
    height: calc(var(--sp-1) * 90);
    height: 28rem;
    padding-inline: var(--sp-3);
    padding-bottom: 4rem;
    overflow-y: scroll;
  }
  .suggestions-lists::-webkit-scrollbar {
    display: none;
  }
  .suggestions-lists::-webkit-scrollbar-thumb {
    border-color: var(--shade8);
  }
  .suggestions-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /* ----------------------------------- */
  /* No Search Results */
  .suggestions-list--no-results {
    --q-mark-color: var(--shade10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 var(--sp-8);
    height: 100%;
  }
  .suggestions-list--no-results .type-body1 {
    color: var(--shade2);
    margin-top: var(--sp-1);
  }
  .suggestions-list--no-results .question-mark {
    margin-bottom: var(--sp-6);
  }
  .suggestions-list--no-results .question-mark .inner {
    fill: var(--q-mark-color);
  }
  .suggestions-list--no-results .question-mark .outer {
    stroke: var(--q-mark-color);
  }
  .suggestions-list--no-results .question-mark .question {
    fill: var(--shade3);
  }

  /* ----------------------------------- */
  /* Suggestions Group */
  .suggestion-group {
    padding: var(--sp-2);
    background: var(--shade5-25);
    border-radius: var(--br);
  }
  .suggestion-group summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  }
  .suggestion-group summary h2 {
    display: flex;
    align-items: center;
  }
  .suggestion-group ol {
    padding-block: var(--sp-1);
  }
  .suggestion-group .group-title {
    display: flex;
    align-items: center;
    padding: var(--sp-0_5) var(--sp-1_5) 0;
    color: var(--shade1);
    background: var(--shade1-15);
    border-radius: var(--br-sm);
  }
  .suggestion-group[data-has-results="true"] .group-title {
    color: hsla(var(--phase-color) / 1);
    background: hsla(var(--phase-color) / 0.15);
  }
  .suggestion-group .group-title .champ-img {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: calc(var(--sp-1) * -1);
    margin-right: var(--sp-1_5);
    margin-top: calc(var(--sp-0_5) * -1);
    background: var(--shade10-50);
    border-radius: var(--br-sm);
  }
  .suggestion-group .chevron {
    width: var(--sp-4);
    height: var(--sp-4);
    fill: var(--shade0-25);
  }
  .suggestion-group .chevron {
    transform: rotate(0.5turn);
    transition: transform var(--transition);
  }
  .suggestion-group[data-has-results="false"] .chevron {
    opacity: 0.38;
  }
  .suggestion-group .show-more {
  }
  .suggestion-group button {
    display: block;
    width: 100%;
    padding: var(--sp-0_5) 0;
    font-weight: 600;
    font-size: var(--sp-3);
    line-height: var(--sp-6);
    letter-spacing: -0.009em;
    text-align: center;
    color: var(--shade2);
    background: var(--shade3-25);
    border-radius: var(--br-sm);
    cursor: pointer;
  }
  .suggestion-group button:hover {
    color: var(--shade1);
    background: var(--shade2-15);
  }

  /* ----------------------------------- */
  /* Suggestion Group w/ no results */
  .suggestion-group[data-has-results="false"] {
    display: none;
  }
  .suggestion-group[data-has-results="false"] .group-title--waiting {
    opacity: 1;
    visibility: visible;
  }
  .suggestion-group[open] .chevron {
    transform: rotate(0turn);
  }
  .suggestion-group[data-has-results="false"] .chevron {
    display: none;
  }

  /* ----------------------------------- */
  /* Suggestion Group w/ a data fetch failure */
  .suggestion-group[data-missing-data="true"] {
    background: hsla(var(--red-hsl) / 0.15);
  }
  .suggestion-group[data-missing-data="true"] .group-title {
    color: var(--red);
    background: hsla(var(--red-hsl) / 0.15);
  }

  [data-phase="banning"] .icon-container .ban {
    display: block;
  }
  [data-phase="finalizing"] .icon-container .checkmark,
  [data-phase="picking"] .icon-container .checkmark {
    display: block;
  }

  /* ----------------------------------- */
  /* Empty Selected Suggestion */
  .empty-suggestions[data-hide="true"] {
    display: none;
  }
  .section-title {
    /* margin-bottom: var(--sp-1); */
  }
  .section-subtitle {
    margin-bottom: var(--sp-4);
    color: var(--shade2);
  }
  .block-title {
    color: hsla(var(--phase-color) / 1);
    background: hsla(var(--phase-color) / 0.15);
  }

  .personal-suggestions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    gap: 0.5rem;
  }
  .personal-suggestions .block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--sp-4);
    padding-top: var(--sp-6);
    background: var(--shade6-25);
    border-radius: var(--br-lg);
    cursor: pointer;
    transition:
      background var(--transition),
      box-shadow var(--transition);
  }
  .personal-suggestions .block-item:hover {
    background: hsla(var(--phase-color) / 0.05);
  }
  .personal-suggestions .block-item:nth-child(1),
  .personal-suggestions .block-item:nth-child(2),
  .personal-suggestions .block-item:nth-child(3) {
    border-bottom: 1px solid var(--dark-bg);
  }
  .personal-suggestions .champ-img-container {
    position: relative;
    margin-bottom: 1rem;
  }
  .personal-suggestions .tier-icon {
    position: absolute;
    width: 1.75rem;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);

    .tier-bg {
      fill: var(--shade8);
    }
  }
  .personal-suggestions .champ-img {
    width: 3rem;
    border-radius: var(--br-lg);
    transition: box-shadow var(--transition);
    box-shadow: 0 0 0 2px var(--tier-color);
  }
  .personal-suggestions .index {
    color: var(--shade2);
  }
  .personal-suggestions .block-item:nth-child(1) .index {
    color: var(--yellow);
  }
  .personal-suggestions button {
    text-align: center;
    color: var(--shade1);
    margin-top: 0.5rem;
    padding: 0 var(--sp-3);
    height: var(--sp-7);
    width: 100%;
    background: hsla(var(--shade2-hsl) / 0.15);
    border-radius: var(--br);
    box-shadow: var(--highlight);
    cursor: pointer;
    transition:
      background var(--transition),
      color var(--transition);
  }
  .personal-suggestions .block-item[data-disabled="true"] {
    pointer-events: none;
  }

  .personal-suggestions .block-item[data-disabled="true"] button {
    opacity: 0.38;
  }
  .personal-suggestions .block-item:hover button {
    color: hsla(var(--phase-color) / 1);
    background: hsla(var(--phase-color) / 0.15);
  }

  .empty-loading,
  .empty-error {
    display: none;
  }
  @keyframes pulse {
    0%,
    100% {
      opacity: 0;
      transform: scale(0.9);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
  }
  .empty-loading .dot {
    background: var(--shade3);
    height: var(--sp-2_5);
    width: var(--sp-2_5);
    border-radius: 50%;
  }
  .empty-icon {
    position: relative;
    padding: var(--sp-3_5);
    margin-bottom: var(--sp-8);
    background: var(--shade10);
    border-radius: 50%;
  }
  .empty-icon::after {
    content: "";
    position: absolute;
    inset: -0.5rem;
    box-shadow: inset 0 0 0 2px var(--shade10);
    border-radius: 50%;
  }
  .empty-icon svg {
    fill: var(--shade3);
  }
  .empty-suggestions[data-loading="true"] .empty-icon,
  .empty-suggestions[data-loading="true"] .empty-text,
  .empty-suggestions[data-error="true"] .empty-icon,
  .empty-suggestions[data-error="true"] .empty-text {
    display: none;
  }
  .empty-suggestions[data-loading="true"] .empty-loading {
    display: flex;
    gap: var(--sp-2);
  }
  .empty-suggestions[data-loading="true"] .empty-loading .dot {
    animation: pulse 1s calc(var(--i) * 0.15s) ease-in-out infinite;
  }
  .empty-suggestions[data-error="true"] .empty-error {
    display: block;
    color: var(--red);
  }

  .suggestions-loading {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0;
    background: var(--shade9-75);
    z-index: 3;
  }
  .suggestions-loading .circle-loader,
  .suggestions-loading .suggestions-broken {
    width: 10rem;
  }
  .suggestions-loading .suggestions-broken {
    display: none;
    fill: var(--shade5);
  }
  .suggestions-loading p {
    color: var(--shade2);
  }
  .suggestions-loading[data-is-loading="true"] {
    display: flex;
  }
  .suggestions-loading[data-did-error="true"] .circle-loader {
    display: none;
  }
  .suggestions-loading[data-did-error="true"] .suggestions-broken {
    display: block;
  }
  .suggestions-loading .error-message {
    text-decoration: underline;
    margin-top: 1rem;
  }
`;

export const cssSearch = () => css`
  :where(&) {
    flex: 1;
    position: relative;
    background: var(--shade6);
    padding-left: var(--sp-9);
    width: 11ch;
    border-radius: var(--br);
    color: var(--shade1);
    box-shadow: var(--highlight);
  }

  &:focus-within {
    background: var(--shade3);
    box-shadow: 0 0 0 3px var(--shade2-15);
  }
  &,
  & .search-input {
    height: var(--sp-9);
  }
  .search-input {
    background: transparent;
    border: none;
    padding: 0;
    width: 100%;
    color: var(--shade0);
    outline: none;
  }
  .search-input::-webkit-input-placeholder {
    color: var(--shade2);
    font-weight: 600;
    font-size: var(--sp-3_5);
    line-height: var(--sp-5);
    letter-spacing: -0.009em;
  }
  .search-icon,
  .cancel-icon {
    position: absolute;
    width: var(--sp-5);
    height: var(--sp-5);
    top: 50%;
    transform: translateY(-50%);
  }
  .search-icon {
    left: var(--sp-3);
  }
  .cancel-icon {
    display: none;
    right: var(--sp-3);
    fill: var(--shade1);
    pointer-events: all;
    cursor: pointer;
  }
  .cancel-icon:hover {
    fill: var(--shade0);
  }
  &[data-search-active="true"] .cancel-icon {
    display: block;
  }
`;

export const cssSuggestionItem = () => css`
  & {
    --bg: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--bg);
    padding-block: var(--sp-1);
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    .copy {
      opacity: 1;
    }
  }

  .copy {
    position: absolute;
    inset: 0;
    display: grid;
    place-content: center;
    background: var(--shade7-75);
    opacity: 0;
    z-index: 1;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
  }

  &[data-selected="true"] {
    --bg: var(--shade1-15) !important;
  }
  &[data-selected="true"] .champ-portrait {
    box-shadow: 0 0 0 2px hsla(var(--phase-color) / 1);
  }
  .champ-container {
    position: relative;
    display: flex;
    gap: var(--sp-1);
  }

  .champ-portrait {
    display: block;
    aspect-ratio: 1;
    width: var(--sp-9);
    background: var(--skeleton-color);
    border-radius: var(--br);
  }
  .icon-container {
    position: absolute;
    bottom: -0.25rem;
    right: -0.25rem;
    padding: var(--sp-0_5);
    border-radius: var(--br);
    opacity: 0;
    visibility: hidden;
  }
  .icon-container::before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--bg);
    border-radius: var(--br);
  }
  .icon-container svg {
    width: var(--sp-4);
    color: hsla(var(--phase-color) / 1);
  }
  &:hover .icon-container,
  &[data-selected="true"] .icon-container {
    opacity: 1;
    visibility: visible;
  }
  .icon-container .checkmark,
  .icon-container .ban {
    display: none;
  }
  .counters {
    --champ-size: var(--sp-4);
    margin: 0;
    padding: 0;
    display: flex;
    gap: -0.5rem;
  }
  .counter.champ-img {
    width: var(--champ-size);
    border-radius: 50%;
  }
  .champ-info {
    display: flex;
    flex-direction: column;
    gap: var(--sp-0_5);
  }
  .champ-name {
    width: 11ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .duo & {
      width: 9ch;
    }
  }
  .champ-stats {
    display: flex;
    align-items: center;
    gap: var(--sp-1_5);
    color: var(--shade2);
    font-weight: 500;
    font-size: var(--sp-2_5);
    line-height: 1;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  .tier-icon {
    width: var(--sp-4);
    height: var(--sp-4);
    transform: translateY(1px);
  }
  .score {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--sp-0_5);
    color: var(--shade2);
  }
  .score > * {
    display: flex;
    gap: 0.25rem;
    text-align: right;
  }
  .score-val {
    --stat-color: var(--shade1-hsl);
    position: relative;
    width: var(--sp-11);
    color: var(--stat-color);
    text-align: center;
  }

  &.duo {
    pointer-events: none;

    .champ-portrait {
      width: var(--sp-7);
    }
  }
  [data-queue-id="1700"] & {
    padding: 0;
  }

  &[data-banned="true"] {
    pointer-events: none !important;
    filter: saturate(0);
    opacity: 0.38;
  }
`;
